import React from 'react'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'

export default function AdminAddProducttoGiftShop() {
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full pb-14 pt-3 bg-[#EFF2F2]'>
                    <div className='p-4 px-14 bg-white w-full hidden lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>Giftshop</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>
                    <div className='m-6'>
                        <Link to={'/adminshop'} >
                            <img src={Arrow} alt='arrow direction' />
                        </Link>
                        <div className='mt-6'>
                            <p className='text-[#424038] font-medium'>Gift Information</p>
                        </div>
                    </div>

                    <form className='m-6 '>

                        <label className='text-[#424038] ' >
                            <p>Title</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Excerpt</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>


                        <div className='grid grid-cols-2 gap-4 mt-6'>
                            <div>
                                <label className='text-[#424038] ' >
                                    <p>Amount</p>
                                    <input
                                        type='text'
                                        name='amount'
                                        placeholder='Enter here'
                                        className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                                    />
                                </label>
                            </div>
                            <div>
                                <label className='text-[#424038] ' >
                                    <p>Quantity</p>
                                    <input
                                        type='number'
                                        name='quantity'
                                        placeholder='Enter here'
                                        className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                                    />
                                </label>
                            </div>
                        </div>


                        <div className='mt-6 mb-1 text-[#424038] '>
                            <p>Image</p>
                        </div>
                        <div className='mb-8 '>
                            {/* div for main product image */}
                            <div className='mb-6 '>
                                <input type='file' />
                            </div>

                            {/* for other images */}
                            <div className='mb-1 text-[#424038] '>
                                <p>Other photos</p>
                            </div>
                            <div className='lg:flex  '>
                                <div className=' '>
                                    <div className=' '>
                                        <input type='file' />
                                    </div>
                                    <div className=''>
                                        <input type='file' className='' />
                                    </div>
                                </div>
                                <div className=' '>
                                    <div className=' '>
                                        <input type='file' />
                                    </div>
                                    <div className=''>
                                        <input type='file' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label className='text-[#424038] ' >Description</label><br />
                        <textarea 
                        name='description'
                        className='h-36 mb-6 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>

                        </textarea>

                        <label className='text-[#424038] '>Additional Information</label><br />
                        <textarea className='h-24 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>

                        </textarea>


                        <h1 className='mt-8 text-[#424038] font-bold text-1xl'>Shipping Information</h1>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Delivery Option</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <button className='bg-[#424038] text-white w-full mb-36 mt-12 p-2'>Post</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
