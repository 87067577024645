import React from 'react'
import { useParams } from 'react-router-dom'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'
import Data from '../data/gallery.json'
import Image1 from '../assets/admin/image 12.png'

function AdminGalleryDetail() {
    const { id } = useParams()
    const galleryDetail = Data.find(item => {
        return item.id === parseInt(id)
    })
    if (!galleryDetail) {
        return <section>Loading....</section>

    }
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>
                <div className='w-full'>

                    <div className=' mx-14 my-4 flex text-justify justify-between'>
                        <div><p className='font-medium'>Gallery</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                        <div className='p-8'>
                            <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>EXHIBITIONS</h1>
                            <p className='font-medium text-[12px] pt-2 text-[#818285]'>Details on all exhibitions</p>
                        </div>
                        <div className='mt-8 md:mt-0 mx-2'>
                            <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                        </div>
                    </div>

                    <div className='flex justify-between mx-4 lg:mx-20 mt-14'>
                        <div>
                            <Link to={'/admingallery'}>
                                <img src={Arrow} alt='arrow' />
                            </Link>
                        </div>
                        <div>
                            <Link to={`/updateartwork/${galleryDetail.id}`} className='bg-[#2A564C] text-white py-2 px-6 text-center'>
                                Edit Info
                            </Link>
                        </div>
                    </div>


                    <div className='lg:grid grid-cols-2 items-center justify-center mx-4 lg:mx-28 mt-10'  >
                        <div className='md:flex-col flex justify-center'>
                            <img className='lg:w-[500px] h-[500px] ' src={galleryDetail.image} alt={galleryDetail.title} />
                        </div>
                        <div className='lg:w-[500px] pt-8 lg:pt-0 lg:h-[500px]  leading-10 items-center justify-center'>
                            <h1 className='text-3xl tracking-widest px-4 pb-4 font-bold'>{galleryDetail.title}</h1>
                            <p className='px-4'>{galleryDetail.description.substring(0, 300)}</p>
                        </div>
                    </div>
                    <div className='bg-[#FFFAF5]  lg:mx-28 lg:my-20 mx-4 my-8 lg:p-10'>
                        <h1 className='py-4 tracking-widest p-4 font-bold text-3xl '>Art Description</h1>
                        <p className='leading-10 p-4'><span className='font-semibold'>description: </span>{galleryDetail.description}</p>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminGalleryDetail