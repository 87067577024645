import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Bg from '../assets/signup/bg.jpeg';
import axios from 'axios';
import Popup from '../components/Notification';

const Login = () => {
 const navigate = useNavigate();

 const [formData, setFormData] = useState({
  email: '',
  password: '',
  rememberMe: false,
  loginType: 'Guest',
 });

 const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState('');
 const [showErrorModal, setShowErrorModal] = useState(false);
 const [successMessage, setSuccessMessage] = useState('');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const modalRef = useRef(null);

 useEffect(() => {
  let timer;
  if (showErrorModal) {
   timer = setTimeout(() => {
    setShowErrorModal(false);
   }, 10000);
  }

  const handleClickOutside = (event) => {
   if (modalRef.current && !modalRef.current.contains(event.target)) {
    setShowErrorModal(false);
   }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
   clearTimeout(timer);
   document.removeEventListener('mousedown', handleClickOutside);
  };
 }, [showErrorModal]);

 const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  setFormData((prevState) => ({
   ...prevState,
   [name]: type === 'checkbox' ? checked : value,
  }));
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setIsLoading(true);

  try {
   const response = await axios.post(
    'https://isinrimuseum-backend.fly.dev/api/v1/auth/login',
    {
     email: formData.email,
     password: formData.password,
     userType: formData.loginType,
    }
   );

   if (response.status === 200) {
    setSuccessMessage('Login successful, redirecting...');
    setShowSuccessModal(true);
    setFormData({ email: '', password: '', loginType: '' });
   }

   localStorage.setItem('token', response.data.token);
   setTimeout(() => {
    if (formData.loginType === 'Admin') {
      navigate('/admin');
    } else if (formData.loginType === 'Guest') {
      navigate('/');
    } else if (formData.loginType === 'Exhibitor') {
      navigate('/exhibition');
    }
  }, 2000);
  } catch (err) {
   setError(err.response ? err.response.data.details : err.message);
   setShowErrorModal(true);
   console.error('Login error:', err);
  }finally {
   setIsLoading(false);
  }
 };

 const myBg = {
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)),url(${Bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
 };

 return (
  <div style={myBg}>
   {(showErrorModal || showSuccessModal) && (
    <div className='fixed top-4 right-1/4 z-50' ref={modalRef}>
     <Popup
      type={showSuccessModal ? 'success' : 'error'}
      message={showSuccessModal ? successMessage : error}
      onClose={() => {
       setShowErrorModal(false);
       setShowSuccessModal(false);
      }}
     />
    </div>
   )}

   {isLoading && (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
     <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
    </div>
   )}

   <div className=' py-24 h-fit'>
    <div className='bg-white items-center justify-center mx-4 md:mx-[8%] lg:mx-[25%] lg:p-8 rounded-md text-center'>
     <div className='pt-12 '>
      <div>
       <h1 className='text-[#1A1C21]  font-bold md:tracking-[6px] mb-10  tracking-widest mx-1 text-2xl lg:text-[34px] '>
        Login
       </h1>
       <p className='text-[#2F3035] text-[13px] pt-2 md:text-[19px]'>
        Please enter your details to continue
       </p>
      </div>
      {/* <Link className='flex space-x-4 items-center justify-center  border-[#58595D] border mx-6 lg:mx-28 py-4 my-16 rounded'>
       <div>
        <img src={Google} alt={'google'} />
       </div>
       <div>
        <p>Login with Google</p>
       </div>
      </Link> */}
      {/* <div className=' grid grid-cols-3 mt-[-40px] pb-10 mx-6 lg:mx-28'>
       <hr className='mr-[-25px]' />
       <p className='mt-[-16px]'>or</p> <hr className='ml-[-30px]' />
       <div></div>
      </div> */}
     </div>
     <form className='mx-6 lg:mx-28' onSubmit={handleSubmit}>
      <label>
       <input
        required
        type='email'
        name='email'
        placeholder='Email'
        value={formData.email}
        onChange={handleInputChange}
        className=' placeholder:text-[14px] px-4 py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
       />
      </label>

      <label>
       <input
        required
        type='password'
        name='password'
        placeholder='Password'
        value={formData.password}
        onChange={handleInputChange}
        className=' placeholder:text-[14px] px-4 py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2  lg:my-6'
       />
      </label>
      <label className='block mb-6'>
       <select
        required
        name='loginType'
        value={formData.loginType}
        onChange={handleInputChange}
        className='mt-1 block w-full py-4 px-3 border-b border-[#6C6E71] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2A564C] focus:border-[#2A564C] text-[14px] md:text-[18px]'
       >
        <option value=''>Select login type</option>
        <option value='Admin'>Admin</option>
        <option value='Exhibitor'>Exhibitor</option>
        <option value='Guest'>Guest</option>
       </select>
      </label>
      <div className='flex justify-between'>
       <label className='text-[12px] md:text-[14px]'>
        <input
         className='mr-2 pt-2'
         type='checkbox'
         name='rememberMe'
         checked={formData.rememberMe}
         onChange={handleInputChange}
        />
        Remember me
       </label>
       <div>
        <Link className='text-[12px] md:text-[14px]' to={'/forgotpassword'}>
         Forgotten Password
        </Link>
       </div>
      </div>

      <div className='my-14 md:my-20 pb-8'>
       <button
        type='submit'
        className='bg-[#2A564C] w-full text-white py-2 md:py-5 rounded lg:text-2xl '
       >
        Login
       </button>
       <p className='pt-6 text-[#6C6E71] text-[12px] md:text-[14px]'>
        Don't have an account?{' '}
        <Link className='font-bold text-black' to={'/signup'}>
         Sign up
        </Link>
       </p>
      </div>
     </form>
    </div>
   </div>
  </div>
 );
};

export default Login;
