import React from 'react'
import { useParams } from 'react-router-dom'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'
import Image1 from '../assets/admin/image 12.png'
import HistoryData from '../data/history.json'

function AdminHistoryDetailPage() {
    const { id } = useParams()
    const historyDetail = HistoryData.find(item => {
        return item.id === parseInt(id)
    })
    if (!historyDetail) {
        return <section>Loading....</section>

    }
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>
                <div className='w-full'>

                    <div className='hidden  mx-14 my-4 lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>History</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                        <div className='p-8'>
                            <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>LEARN HISTORY</h1>
                            <p className='font-medium text-[12px] pt-2 text-[#818285]'>Here's all you need to know about history in a glance</p>
                        </div>
                        <div className='mt-8 md:mt-0 mx-2'>
                            <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                        </div>
                    </div>

                    <div className='flex justify-between mx-4 lg:mx-20 mt-14'>
                        <div>
                            <Link to={'/adminhistory'}>
                                <img src={Arrow} alt='arrow' />
                            </Link>
                        </div>
                        <div>
                            <Link to={`/updatehistory/${historyDetail.id}`} className='bg-[#2A564C] text-white py-2 px-6 text-center'>
                                Edit Info
                            </Link>
                        </div>
                    </div>

                    <div className='flex justify-center my-10'>
                        <h1 className='lg:font-bold lg:text-4xl text-2xl font-medium tracking-widest'>{historyDetail.title}</h1>
                    </div>
                    <div className='lg:mx-10 mx-2'>
                        <p className='text-center'>{historyDetail.description}</p>
                    </div>
                    <div className='flex justify-center my-8'>
                        <img src={historyDetail.image} alt={historyDetail.title} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminHistoryDetailPage