import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Bg from '../assets/signup/bg.jpeg';
import Popup from '../components/Notification';

const Signup = () => {
    const myBg = {
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const modalRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (showErrorModal) {
            timer = setTimeout(() => {
                setShowErrorModal(false);
            }, 10000);
        }

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowErrorModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            clearTimeout(timer);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showErrorModal]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            setShowErrorModal(true);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            setShowErrorModal(true);
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                'https://isinrimuseum-backend.fly.dev/api/v1/auth/signup',
                {
                    name: formData.fullName,
                    email: formData.email,
                    phone: formData.phoneNumber,
                    password: formData.password,
                    userType: formData.userType,
                }
            );

            if (response.status === 201) {
                setSuccessMessage('Signup successful, redirecting to login page...');
                setShowSuccessModal(true);
                setFormData({ fullName: '', email: '', password: '', phoneNumber: '' });
            }

            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (err) {
            setError(err.response ? err.response.data.details : err.message);
            setShowErrorModal(true);
            console.error('Signup error:', err);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div style={myBg}>
            {(showErrorModal || showSuccessModal) && (
                <div className='fixed top-4 right-1/4 z-50' ref={modalRef}>
                    <Popup
                        type={showSuccessModal ? 'success' : 'error'}
                        message={showSuccessModal ? successMessage : error}
                        onClose={() => {
                            setShowErrorModal(false);
                            setShowSuccessModal(false);
                        }}
                    />
                </div>
            )}

            {isLoading && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                    <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
                </div>
            )}

            <div className=' py-16'>
                <div className='bg-white items-center justify-center mx-4 md:mx-[8%] lg:mx-[25%] lg:p-8 rounded-md text-center'>
                    <div className='py-12 '>
                        <div>
                            <h1 className='text-[#1A1C21]  font-bold md:tracking-[6px]  tracking-widest mx-1 text-2xl lg:text-[34px] '>
                                Create an account
                            </h1>
                            <p className='text-[#2F3035] text-[13px] pt-2 md:text-[19px]'>
                                Please enter your details to continue
                            </p>
                        </div>

                    </div>
                    <form className='mx-6 lg:mx-28' onSubmit={handleSubmit}>
                        <label>
                            <input
                                required
                                type='text'
                                name='fullName'
                                placeholder='Full Name'
                                value={formData.fullName}
                                onChange={handleChange}
                                className='placeholder:text-[14px] px-4  py-2 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
                            />
                        </label>

                        <label>
                            <input
                                required
                                type='email'
                                name='email'
                                placeholder='Email'
                                value={formData.email}
                                onChange={handleChange}
                                className='placeholder:text-[14px] px-4  py-2 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2  lg:my-6'
                            />
                        </label>

                        <label>
                            <input
                                type='text'
                                name='phoneNumber'
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                placeholder='Phone Number'
                                className='placeholder:text-[14px] px-4  py-2 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 l:my-6'
                            />
                        </label>

                        <label>
                            <input
                                required
                                type='password'
                                name='password'
                                placeholder='Password'
                                value={formData.password}
                                onChange={handleChange}
                                className='placeholder:text-[14px] px-4  py-2 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
                            />
                        </label>

                        <label>
                            <input
                                required
                                type='password'
                                name='confirmPassword'
                                placeholder='Confirm Password'
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className='placeholder:text-[14px] px-4  py-2 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
                            />
                        </label>

                        <select
                            required
                            name='userType'
                            value={formData.userType}
                            onChange={handleChange}
                            className='mt-1 block w-full py-4 px-3 border-b border-[#6C6E71] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2A564C] focus:border-[#2A564C] text-[14px] md:text-[18px]'
                        >
                            <option value=''>Select Account Type</option>
                            <option value='Exhibitor'>Exhibitor</option>
                            <option value='Guest'>Guest</option>
                        </select>

                        <div className='my-14 md:my-20 pb-8'>
                            <button
                                type='submit'
                                className='bg-[#2A564C] w-full text-white py-2 md:py-5 rounded lg:text-1xl '
                            >
                                Sign Up
                            </button>
                            <p className='pt-6 text-[#6C6E71] text-[12px] md:text-[14px]'>
                                Already have an account?{' '}
                                <Link className='font-bold text-black' to={'/login'}>
                                    Login here
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
