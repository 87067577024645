import React from 'react'
import Nav from './Nav'

function AdminSettings() {
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='md:w-full pb-14  bg-[#FCFCFD] pt-8'>
                    <div className='p-4 px-14 bg-white w-full hidden shadow-md lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>Settings</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='lg:mx-14 mx-8 mt-8 lg:mt-14 mb-8 border-b-2 pb-6'>
                        <h1 className='text-[#344054] pb-3 text-2xl'>Account</h1>
                        <p className='text-[##667085]'>Real-time Information and activities of your property</p>
                    </div>
                    <div className='lg:mx-14 mx-8'>
                        <form>
                            <p className='text-[20px]'>Profile picture </p>
                            <p className='mb-4 text-[12px] text-[#344054]'>PNG, JPEG under 20MB</p>
                            <input type='file' name='profilepix' />

                            <h1 className='mt-10 text-2xl font-medium text-[#344054] tracking-widest'>Full Name</h1>
                            <div className='md:grid grid-cols-2 gap-4 mt-6 border-b-2 pb-8'>
                                <label className='text-[#424038] '>
                                    First Name

                                    <input
                                        type='text'
                                        name='firstname'
                                        placeholder='Enter here'
                                        className='border border-[#424038] p-2 outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                                <label className='text-[#424038] '>
                                    First Name

                                    <input
                                        type='text'
                                        name='lastname'
                                        placeholder='Enter here'
                                        className='border border-[#424038] p-2 outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                            </div>

                            <div className='mt-10'>
                                <h1 className='text-2xl font-medium text-[#344054] tracking-widest'>Contact Email</h1>
                                <p className='text-[#58595D] text-[15px]'>Modify your email address</p>
                            </div>
                            <div className='md:grid grid-cols-2 gap-4 mt-6 border-b-2 pb-8'>
                                <label className='text-[#424038] '>
                                    Email

                                    <input
                                        required
                                        type='email'
                                        name='email1'
                                        placeholder='Enter here'
                                        className='border border-[#424038] p-2 outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                                <label className='text-[#424038] '>
                                    Email 2

                                    <input
                                        type='email'
                                        name='email2'
                                        placeholder='Enter here'
                                        className='border p-2 border-[#424038] outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                            </div>

                            <div className='mt-10'>
                                <h1 className='text-2xl font-medium text-[#344054] tracking-widest'>Password</h1>
                                <p className='text-[#58595D] text-[15px]'>Modify your current password</p>
                            </div>
                            <div className='md:grid grid-cols-2 gap-4 mt-6 border-b-2 pb-8'>
                                <label className='text-[#424038] '>
                                    Current password

                                    <input
                                        required
                                        type='password'
                                        name='email1'
                                        placeholder='Enter here'
                                        className='border border-[#424038] p-2 outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                                <label className='text-[#424038] '>
                                    New password

                                    <input
                                        type='email'
                                        name='email2'
                                        placeholder='Enter here'
                                        className='border p-2 border-[#424038] outline-[#424038] bg-[#FCFCFD] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                                    />
                                </label>
                            </div>

                            <button className='bg-[#424038] text-white w-full mb-36 mt-12 p-2'>Update</button>
                    
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSettings