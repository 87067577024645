import React from 'react'
import MenuBar from '../components/MenuBar'
import Data from '../data/gallery.json'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const Gallery = () => {
    // const [galleryData, setGalleryData] = useState([]);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchGalleryData = async () => {
    //         try {
    //             const response = await axios.get('https://api.example.com/gallery');
    //             setGalleryData(response.data);
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching gallery data:', error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchGalleryData();
    // }, []);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // const preferredImage = galleryData.find(item => item.id === 2);
    // const sideImage = galleryData.find(item => item.id === 3);

    const preferredImage = Data.find(item => {
        return item.id === 2
    })
    const sideImage = Data.find(item => {
        return item.id === 3
    })
    const sideImage1 = Data.find(item => {
        return item.id === 15
    })
    const sideImage2 = Data.find(item => {
        return item.id === 14
    })
    const sideImage3 = Data.find(item => {
        return item.id === 13
    })
    const sideImage4 = Data.find(item => {
        return item.id === 7
    })
    const { title, image, description} = preferredImage;

  
    return (
        <div>
            <MenuBar />
            <div className='text-center mt-10'>
                <div>
                    <h1 className='text-[#000] mx-10 text-[55px] lg:text-[75px] lg:font-extrabold font-bold leading-[60px] tracking-[13px]'>Our Gallery</h1>
                </div>
                <div className='lg:mx-[270px] text-[19px] lg:text-[12px]  text-center mx-8 text-[#818285] py-10'>
                    <p>Here, you'll find an exquisite collection of art, history, and culture that spans centuries and continents
                    </p>
                </div>
            </div>

            {/* {fetch data} */}
            <div className='mb-20'>
                <Link to={`/gallery/${preferredImage.id}`}>
                    <div className=' mx-4 lg:mx-[220px] '>
                        <img className=' w-[600px] h-[310px]' src={image} alt={title} />
                        <p className='lg:w-[750px]   md:w-[370px] '><span className='text-[#1A1C21] font-semibold text-[15px]'>{title}</span><br /><span className='text-[12px]'>{description.substring(0, 16)}...</span></p>
                    </div>
                </Link>

                <div className='lg:flex items-center justify-center space-x-2 mt-20'>
                    <div className=' grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 md:gap-4 mx-4 gap-2  mb-8  '>
                        {Data.map((value) => (
                            <div className=' border p-4 bg-[#faf9f6] border-[#DEE0ED] '>
                                <Link to={`/gallery/${value.id}`}>
                                    <img className='   h-[200px] w-[100%] md:w-[400px] md:h-[300px] lg:w-[300px]' src={value.image} alt={value.title} />
                                    <p className='text-[#1A1C21] font-semibold text-[15px] pt-2'>{value.title.substring(0, 25)}</p>
                                    <p className='w-[100%] text-[11px] text-[#6C6E71] ' >{value.description.substring(0, 40)} <br /><i>Read more...</i></p>
                                    
                                </Link>
                            </div>

                        ))}
                    </div>

                    <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-1 mx-8  mb-4'>
                        <Link to={`/gallery/${sideImage.id}`}>
                            <div className='border p-4 bg-[#faf9f6] border-[#DEE0ED] mx-2'>
                                <div className='flex justify-center border-2 p-2'>
                                    <img className=' w-[400px] h-[500px]' src={sideImage.image} alt={sideImage.title} />
                                </div>
                                <div className=' '>
                                    <p >{sideImage.title.substring(0, 10)}</p>
                                    <p>{sideImage.description.substring(0, 41)}<br /><i>Read more...</i></p>
                                </div>
                            </div>
                        </Link>

                        <Link to={`/gallery/${sideImage4.id}`}>
                            <div className='border p-4 bg-[#faf9f6] border-[#DEE0ED] mx-2'>
                                <div className='flex justify-center border-2 p-2'>
                                    <img className=' w-[400px] h-[500px]' src={sideImage4.image} alt={sideImage.title} />
                                </div>
                                <div>
                                    <p>{sideImage.title}</p>
                                    <p>{sideImage1.description.substring(0, 41)} <br /><i>Read more...</i></p>
                                </div>
                            </div>
                        </Link>

                        <Link to={`/gallery/${sideImage3.id}`}>
                            <div className='border p-4 bg-[#faf9f6] border-[#DEE0ED] mx-2'>
                                <div className='flex justify-center border-2 p-2'>
                                    <img className='w-[400px] h-[500px]' src={sideImage3.image} alt={sideImage.title} />
                                </div>
                                <div className=''>
                                    <p>{sideImage.title}</p>
                                    <p>{sideImage1.description.substring(0, 41)}<br /><i>Read more...</i></p>
                                </div>
                            </div>
                        </Link>

                        <Link to={`/gallery/${sideImage2.id}`}>
                            <div className='border p-4 bg-[#faf9f6] border-[#DEE0ED] mx-2'>
                                <div className='flex justify-center border-2 p-2'>
                                    <img className=' w-[400px] h-[500px]' src={sideImage2.image} alt={sideImage.title} />
                                </div>
                                <div className=''>
                                    <p>{sideImage.title}</p>
                                    <p>{sideImage3.description.substring(0, 41)}<br /><i>Read more...</i></p>
                                </div>
                            </div>
                        </Link>

                        <Link to={`/gallery/${sideImage1.id}`}>
                            <div className='border p-4 bg-[#faf9f6] border-[#DEE0ED] mx-2'>
                                <div className='flex justify-center border-2 p-2'>
                                    <img className=' w-[400px] h-[500px]' src={sideImage1.image} alt={sideImage4.title} />
                                </div>
                                <div className=''>
                                    <p>{sideImage1.title}</p>
                                    <p>{sideImage.description.substring(0, 41)}<br /><i>Read more...</i></p>
                                </div>
                            </div>
                        </Link>
                    </div>


                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Gallery