import React, { useState, useRef } from 'react';
import axios from 'axios';
import Popup from '../components/Notification';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const modalRef = useRef(null);

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.post(
                'https://isinrimuseum-backend.fly.dev/api/v1/auth/forgot-password',
                { email }
            );

            if (response.status === 200) {
                setSuccessMessage('Password reset link sent to your email');
                setShowSuccessModal(true);
                setEmail('');
            }
        } catch (err) {
            setError(err.response ? err.response.data.details : err.message);
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className=" flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            {(showErrorModal || showSuccessModal) && (
                <div className="fixed top-4 right-1/4 z-50" ref={modalRef}>
                    <Popup
                        type={showSuccessModal ? 'success' : 'error'}
                        message={showSuccessModal ? successMessage : error}
                        onClose={() => {
                            setShowErrorModal(false);
                            setShowSuccessModal(false);
                        }}
                    />
                </div>
            )}

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
                </div>
            )}

            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-[#2A564C]">
                        Forgot Password
                    </h2>
                    <p className="mt-2 text-center text-[13px] text-gray-600">
                        Enter your email address to reset your password
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#2A564C] hover:bg-[#1A463C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send Reset Link
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};


export default ForgotPassword