import React from 'react'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'

function AddNewExhibition() {
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full pb-14  bg-[#EFF2F2]'>
                    <div className='p-4 px-14 bg-white w-full flex text-justify justify-between'>
                        <div><p className='font-medium'>Exhibition</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>
                    <div className='m-6'>
                        <Link to={'/adminExhibition'} >
                            <img src={Arrow} alt='arrow direction' />
                        </Link>
                        <div className='mt-6'>
                            <p className='text-[#424038] font-medium'>Exhibition Information</p>
                        </div>
                    </div>


                    <form className='mx-4'>
                        {/* div for main event image image */}
                        <div className='mb-6 '>
                            <input type='file' />
                        </div>

                        <label className='text-[#424038] ' >Description</label><br />
                        <textarea 
                        name='description'
                        className='h-36 mb-6 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>
                        </textarea>


                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Titles</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Phone Number</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Date</p>
                            <input
                                type='date'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <label className='text-[#424038] my-2' >
                            <p className='pt-6'>Location</p>
                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded w-full'
                            />
                        </label>
                        <button className='bg-[#424038] text-white w-full mb-36 mt-12 p-2'>Post</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddNewExhibition