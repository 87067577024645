import React from 'react'
import { useParams } from 'react-router-dom'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'
import Image1 from '../assets/admin/image 12.png'
import Phone from '../assets/exhibition/phone.png'
import Clock from '../assets/exhibition/clock.png'
import Calender from '../assets/exhibition/calender.png'
import Location from '../assets/exhibition/location.png'
import PrevoiusExhibition from '../data/allExhibitions.json'

function AdminExhDetailPage() {
    const { id } = useParams()
    const ExhibitionDetail = PrevoiusExhibition.find(item => {
        return item.id === parseInt(id)
    })
    if (!ExhibitionDetail) {
        return <section>Loading....</section>

    }
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>
                <div className='w-full'>

                    <div className=' mx-14 my-4 flex text-justify justify-between'>
                        <div><p className='font-medium'>Exhibition</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                        <div className='p-8'>
                            <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>EXHIBITIONS</h1>
                            <p className='font-medium text-[12px] pt-2 text-[#818285]'>Details on all exhibitions</p>
                        </div>
                        <div className='mt-8 md:mt-0 mx-2'>
                            <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                        </div>
                    </div>

                    <div className='flex justify-between mx-4 lg:mx-20 mt-14'>
                        <div>
                            <Link to={'/adminExhibition'}>
                                <img src={Arrow} alt='arrow' />
                            </Link>
                        </div>
                        <div>
                            <Link to={`/updateexhibition/${ExhibitionDetail.id}`} className='bg-[#2A564C] text-white py-2 px-6 text-center'>
                                Edit Info
                            </Link>
                        </div>
                    </div>

                    <div className='flex mt-8 mx-4 items-center  justify-center lg:mx-36 md:mx-48 '>
                        <img className='w-[1100px] h-[400px] border-4 border-[#F5E0CB]' src={ExhibitionDetail.image} alt={ExhibitionDetail.title} />
                    </div>

                    <div className='bg-[#FEF9F4] px-4  mt-[-200px]'>
                        <div className=' my-28 lg:flex gap-4 lg:mx-0 mx-4 md:mx-48 pt-32' >
                            <div className='bg-[#FDFDFD] border p-4  lg:w-[700px] mb-10 lg:mb-0'>
                                <h1 className='text-[#1A1C21] font-bold tracking-widest text-[36px] pt-8 pb-8'>About {ExhibitionDetail.title}</h1>
                                <p className='leading-[35px] text-[15px] text-[#818285]'>{ExhibitionDetail.description}</p>
                                {/* <div className='hidden lg:block py-16 '>
                            <Link to={'/bookticket'} className='bg-[#2A564C] border border-[#2A564C] rounded text-[#F6F8F8] px-14 py-4'>Book ticket</Link>
                        </div> */}


                                {/* {mobile button} */}

                                {/* <ul className='lg:hidden'>
                            <li className='bg-[#2A564C]  my-8  py-5 text-center rounded-sm text-white'><Link className='text-[20px]' to={'/bookticket'}>Book ticket</Link></li>
                        </ul> */}
                            </div>

                            {/* {exhibition info} */}
                            <div className='border px-4 bg-[#FDFDFD] pt-8 ' >
                                <h1 className='text-[#1A1C21] font-bold tracking-widest text-[36px]'>Exhibition Info</h1>
                                <p className='text-[#818285] eading-[30px] text-[15px]'>{ExhibitionDetail.description.substring(0, 70)}</p>
                                <div className='flex gap-4 text-[#818285] pt-8 '>
                                    <img className='' src={Phone} alt='phone' />
                                    <p className='text-[18px]'>0812 345 6789</p>
                                </div>

                                <div className='flex gap-4 text-[#818285] pt-8 '>
                                    <img className='' src={Calender} alt='calender' />
                                    <p className='text-[18px]'>{ExhibitionDetail.date}</p>
                                </div>

                                <div className='flex gap-4 text-[#818285] pt-8 '>
                                    <img className='' src={Clock} alt='clock' />
                                    <p className='text-[18px]'>02:00 PM</p>
                                </div>

                                <div className='flex gap-4 text-[#818285] pt-8 '>
                                    <img className='' src={Location} alt='phone' />
                                    <p className='text-[18px]'>Isi-Nri Musuem Enugwu Ukwu</p>
                                </div>

                                {/* <div className='hidden   float-none lg:flex justify-center pt-14'>
                            <Link to={'/bookticket'} className='bg-[#2A564C] border border-[#2A564C] rounded text-[#F6F8F8] px-32 py-4'>Book ticket</Link>
                        </div> */}

                                {/* {mobile button} */}
                                {/* <ul className='lg:hidden'>
                            <li className='bg-[#2A564C]  my-8  py-5 text-center rounded-sm text-white'><Link className='text-[20px]' to={'/bookticket'}>Book ticket</Link></li>
                        </ul> */}
                            </div>
                        </div>
                    </div>

                    {/* {photo speakes} */}
                    <div className='bg-[#20413A] '>
                        <div className='pt-20 mx-4  pb-10 xl:mx-40 lg:mx-20'>
                            <h1 className='text-[#F88603] text-3xl font-semibold tracking-wider'>Photo speaks</h1>
                        </div>
                        <div className='md:flex items-center justify-center gap-4 pb-16 mx-4 lspace-y-4 md:space-y-0' >
                            <div className='space-y-4'>
                                <div className='flex items-center justify-center '>
                                    <img className='flex items-center justify-center w-[500px] md:w-[813px] md:h-[350px]' src={ExhibitionDetail.image2[0]} alt={ExhibitionDetail.title} />
                                </div>
                                <div className='md:flex gap-4 space-y-4 md:space-y-0'>
                                    <div className='flex items-center justify-center '>
                                        <img className='w-[500px] md:w-[400px] md:h-[300px]' src={ExhibitionDetail.image2[1]} alt={ExhibitionDetail.title} />
                                    </div>
                                    <div className='flex items-center justify-center '>
                                        <img className='w-[500px] md:w-[400px] md:h-[300px]' src={ExhibitionDetail.image2[2]} alt={ExhibitionDetail.title} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center '>
                                <img className='w-[500px] md:w-[450px] md:h-[660px]' src={ExhibitionDetail.image2[3]} alt={ExhibitionDetail.title} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminExhDetailPage