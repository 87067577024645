import React from 'react'
import Nav from './Nav'
import { Link } from 'react-router-dom'
import Image1 from '../assets/admin/image 12.png'
import MiniCard from '../assets/admin/mini card.png'
import AddItem from '../assets/admin/add.png'
import HistoryDate from '../data/history.json'

function AdminHistory() {
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full'>

                    <div className=' hidden  mx-14 my-4 lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>History</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                        <div className='p-8'>
                            <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>Gallery</h1>
                            <p className='font-medium text-[12px] pt-2 text-[#818285]'>Here's all you need to know about the gallery at a glance.</p>
                        </div>
                        <div className='mt-8 md:mt-0 mx-2'>
                            <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                        </div>
                    </div>


                    <div className='md:flex  gap-4 mt-10 space-y-4 md:space-y-0 mx-2 md:mx-6'>
                        <div className='flex bg-[#F2F2F3] md:px-10 py-4 px-4 gap-2 rounded-md'>
                            <div>
                                <img src={MiniCard} alt='all products' />
                            </div>
                            <div>
                                <p className='text-[13px]'>All Art works</p>
                                <p className='font-medium text-[18px]'>1234</p>
                            </div>
                        </div>

                        <Link to={'/addhistory'} className='flex bg-[#FDDDB8]  gap-2 md:px-10 py-4 px-4  rounded-md'>
                            <div>
                                <img src={AddItem} alt='add products' />
                            </div>
                            <div className='text-[13px] mt-2'>Add New</div>
                        </Link>
                    </div>

                    {/* fetch art work from json file */}
                    <div className="border mx-2 md:mx-8 my-8 rounded-md">

                        <div className=" md:flex justify-between mt-4 mx-2  md:mx-10 md:mt-8 mb-12 space-y-2 md:space-y-0">
                            <div>
                                <h1 className="text-[#2A564C] font-semibold tracking-widest ">PUBLICATIONS</h1>
                            </div>

                        </div>
                        <table className="flex  justify-center">
                            <div className="w-full ">


                                <div className=" flex justify-center p-4 md:w-full md:space-x-32 bg-[#F2F2F3] text-[#2A564C] space-x-8 text-[11px]  md:text-[16px]">
                                 
                                    <div>PUBLICATION</div>
                                    <div>TITLE</div>
                                    <div>AUTHOR</div>
                                    <div>DATE PUBLISHED</div>
                                </div>

                                {HistoryDate.map((val) => (
                                    <Link to={`/adminhistorydetail/${val.id}`}>
                                        <tr className=" flex justify-center border space-x-8 md:space-x-36 p-4" key={val.id}>
                                            <td className="flex">
                                                <img className='md:w-[200px] md:max-w-200px] w-[200px] max-w-[100px] h-[50px]  md:h-[100px] md:p-4' src={val.image} alt={val.title} />
                                            </td>
                                            <td>
                                                <p>{val.title}</p>
                                            </td>
                                            

                                            <td>
                                                <p>{val.author}</p>
                                            </td>
                                            <td>
                                                <p>{val.date}</p>
                                            </td>

                                        </tr>
                                    </Link>
                                ))}

                            </div>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminHistory