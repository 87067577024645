import React from 'react'
import Nav from './Nav'
import Arrow from '../assets/admin/Arrow 1.png'
import { Link } from 'react-router-dom'

function AdminHistoryUpdate() {
  return (
    <div>
        <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full pb-14  bg-[#EFF2F2]'>
                    <div className='p-4 px-14 bg-white w-full hidden lg:flex text-justify justify-between'>
                        <div><p className='font-medium'>History</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>
                    <div className='m-6 pt-4'>
                        <Link to={'/admingallery'} >
                            <img src={Arrow} alt='arrow direction' />
                        </Link>
                        <div className='mt-6'>
                            <p className='text-[#424038] font-medium'>History Information</p>
                        </div>
                    </div>

                    <form className='m-6 '>

                        <label className='text-[#424038] '>
                            Title

                            <input
                                type='text'
                                name='title'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                            />
                        </label>
                        <label className='text-[#424038] '>
                            Author

                            <input
                                type='text'
                                name='author'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                            />
                        </label>
                        <label className='text-[#424038] '>
                            Date

                            <input
                                type='text'
                                name='date'
                                placeholder='Enter here'
                                className='border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded  mb-4 w-full'
                            />
                        </label>
                        <div className='my-6 '>
                            <input type='file' />
                        </div>
                        <label className='text-[#424038] ' >Description</label><br />
                        <textarea
                            name='description'
                            className='h-36 mb-6 w-full border border-[#424038] outline-[#424038] bg-[#EFF2F2] placeholder:text-[#424038] placeholder:text-[12px] placeholder:pl-4 rounded'>
                        </textarea>

                        <button className='bg-[#424038] text-white w-full mb-36 mt-12 p-2'>Update</button>
                    
                    </form>
                </div>
            </div>
    </div>
  )
}

export default AdminHistoryUpdate