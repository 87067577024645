import React from "react";
import MenuBar from "../components/MenuBar";

// import images

import Afeleoba1 from '../assets/home/IMG_1898.png'
import Afele0ba2 from '../assets/home/Afele_oba2.png'
import Image1 from '../assets/home/Afele_oba.png'
import Backgroundimage from "../assets/home/HomeImage.jpeg";
import Image2 from "../assets/home/bask.jpeg";
import Image7 from "../assets/home/bags.jpeg"
import Whitecap from '../assets/home/whitecap.jpeg'
import Okwaoji from "../assets/home/okwa.jpeg";
import Image3 from "../assets/home/homeimage3.png";
import Image4 from "../assets/home/homeimage4.png";
import Image5 from "../assets/home/homeimage5.png";
import Image6 from "../assets/home/homeimage6.png";

//import mbidi
import Mbidi1 from "../assets/home/Mbidi.png";
import Mbidi2 from "../assets/home/Mbidi (1).png";
import Mbidi3 from "../assets/home/Mbidi (2).png";

// import footer
import Slide from "../components/Slide";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { transition1 } from "../transition";

const Home = () => {
  const myStyle = {
    backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${Backgroundimage})`,
    marginTop: "-80px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div>
      <MenuBar />
      <div className="pt-[80px]">
        <div className="py-8" style={myStyle}>
          <div className="py-[100px]  mx-6 xl:py-[160px] xl:mx-[120px]">
            <div className="text-center xl:text-start">
              <h1 className="text-white text-4xl xl:text-[100px] font-bold  ">
                DISCOVER
              </h1>
              <h1 className="text-[#F88603] pt-2 pb-6  xl:pt-[70px] xl:pb-12 text-4xl font-bold xl:text-[100px]">
                ENUGWU-UKWU
              </h1>
              <p className="text-white text-[23px] xl:text-[17px]">
                Journey Through the Legacy at Isinri Museum
              </p>
            </div>

            <ul className="md:flex xl:flex gap-4 mt-10 items-start self-stretch space-y-6 md:space-y-0 xl:justify-start md:justify-center">
              <li className="text-[#FDFDFD] text-center bg-[#2A564C] py-[20px] px-[30px] rounded-md hover:bg-white hover:text-[#2A564C] ">
                <Link to={"/bookticket"}>Book Ticket</Link>
              </li>
              <li className="border text-center rounded-md py-[20px] px-[30px] text-white hover:bg-white hover:text-[#2a564c]">
                <Link to={"/exhibition"}>Browse Exhibitions</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* {see our culture outside the bg-img} */}

        <div className="py-36 mx-auto md:flex md:gap-20 md:mx-8 md:py-30 items-center justify-center">
          {/* {text} */}
          <motion.div
            initial={{ opacity: 0, x: "-30%" }}
            //animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "50%" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={transition1}
            className="md:max-w-md items-center justify-center mx-4"
          >
            <h1 className="  text-5xl max-w-[80%] md:leading-snug font-secondary text-[#F88603] md:max-w-sm mb-4">
              A new way to look at our culture
            </h1>
            <p className="text-[#1A1C21] text-[18px] md:text-[20px]">
            We are telling the story of a journey that started since 900 AD 
            from the glaring technological lens of the 21st century. 
            Isi Nri Museum stands as the convergence of the annals of Nri Kingdom, 
            carefully curated to preserve our history and showcase its brilliance to a new world.
            </p>
            <ul className="hidden md:flex md:gap-4 mt-6 max-w-md ">
              <li className="bg-[#2A564C] mt-4 py-3 px-6 text-center rounded-sm text-white">
                <Link to={"/about"}>About Us</Link>
              </li>
              <li className="border border-[#2a564c] mt-4 text-center py-3 px-6  rounded-sm  ">
                <Link to={"/history"}>Learn about our history</Link>
              </li>
            </ul>
          </motion.div>
          {/* {image} */}
          <div className="flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: "-30%" }}
              //animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "50%" }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={transition1}
              className=""
            >
              <img
                className="shadow-lg border-4  max-w-[760px] h-[260px] xl:max-w-[860px]  xl:h-[450px] "
                src={Afele0ba2}
                alt="our culture "
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: "30%" }}
              //animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: "50%" }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={transition1}
              className="shadow-lg ml-[-73px] mt-24 border-4"
            >
              <img
                className="max-w-[660px] h-[260px] xl:max-w-[860px]  xl:h-[450px]  "
                src={Afeleoba1}
                alt="our culture"
              />
            </motion.div>
          </div>
          {/* {mobile view} */}
          <ul className="md:hidden md:gap-4 mt-16 items-center justify-center mx-4">
            <li className="bg-[#2A564C] mt-4 py-3 px-6 text-center rounded-sm text-white">
              <Link to={"/about"}>About Us</Link>
            </li>
            <li className="border border-[#2a564c] mt-4 text-center py-3 px-6  rounded-sm  ">
              <Link to={"/history"}>Learn about our history</Link>
            </li>
          </ul>
        </div>

        {/* {Slide and Delve into enugu-ukwu div starts here} */}
        <div className="bg-[#1A1C21]">
          {/* {slide starts} */}
          <div className="mx-4 pt-28 md:mx-4 lg:mx-28 md:py-56 items-center justify-center">
            {/* {text desktop view} */}
            <div className=" hidden md:flex justify-between pb-36">
              <div className="p-10">
                <h1 className="text-[#F88603] text-[50px] mt-[-20px] font-semibold tracking-widest leading-[55px]">
                  {" "}
                  Our Exhibitions <br /> Our Pride
                </h1>
              </div>
              <div className="">
                <Link
                  to={"/allexhibition"}
                  className="border-2  text-white border-white py-4 px-10"
                >
                  View All
                </Link>
              </div>
            </div>

            {/* {text mobile view} */}

            <div className="md:hidden text-center">
              <h1 className="text-[#F88603] text-[35px] font-semibold tracking-widest leading-[38px]">
                {" "}
                Our Exhibitions <br /> Our Pride
              </h1>
            </div>
            {/* {text mobile view ends} */}

            <div>
              <Slide />
            </div>
            <ul className="md:hidden mx-2 text-[20px] items-center justify-center mt-6  ">
              <li className="border-2 mt-4 text-center py-3 px-6  rounded-sm text-white hover:bg-white hover:text-[#1A1C21] ">
                <Link to={"/allexhibition"}>View all</Link>
              </li>
            </ul>
          </div>

          {/* {mbidi starts} */}

          <div className="hidden space-x-16 md:flex  items-center justify-center mx-auto">
            <div>
              <img className="w-20" src={Mbidi1} alt={"mbidi igbo"} />
            </div>
            <div>
              <img className="w-20" src={Mbidi2} alt={"mbidi igbo"} />
            </div>
            <div>
              <img className="w-20" src={Mbidi3} alt={"mbidi igbo"} />
            </div>
          </div>
          {/* {Mbidi ends} */}

          <div className="py-40">
            {/* {text} */}
            <div className=" items-center justify-center text-center mb-10">
              <h1 className="pb-4 tracking-widest text-[#F88603] text-[35px] md:text-[48px]  font-semibold leading-[38px]">
                Delve Into <br className="md:hidden" /> Enugu-Ukwu
              </h1>
              <p className="text-white text-[20px] ">
                PEOPLE &nbsp; HISTORY &nbsp; UNITY
              </p>
            </div>

            {/* {images gride desktop view} */} 
            <div className="hidden md:flex ml-24">
              <div className="items-center justify-center mx-auto max-w-[1090px]  grid grid-cols-2 gap-4 my-16 ">
                <div className="space-y-4">
                  <motion.div
                    initial={{ opacity: 0, y: "-30%" }}
                    exit={{ opacity: 0, x: "50%" }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={transition1}
                  >
                    <img className="flex items-center justify-center border-4 w-[600px] h-[250px]" src={Image6} alt="enugu-ukwu" />
                  </motion.div>

                  <div className="grid grid-cols-2 gap-4 ">
                    <motion.div
                      initial={{ opacity: 0, x: "-30%" }}
                      //animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: "50%" }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={transition1}
                    >
                      <img className="border-4 w-[400px] h-[250px]" src={Image3} alt="enugu-ukwu" />
                    </motion.div>

                    <div>
                      <img
                        className="border-4 w-[400px] h-[250px]"
                        src={Image5}
                        alt="enugu-ukwu"
                      />
                    </div>
                  </div>
                </div>

                <motion.div
                  initial={{ scale: 0.7 }}
                  whileInView={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  //whileInView={{scale: 0.5}}
                  transition={transition1}
                  className=" max-w-[69%] overflow-hidden"
                >
                  <img className="border-4 h-[515px]" src={Image1} alt="enugu-ukwu" />
                </motion.div>
              </div>
            </div>

            {/* {image gride mobile view} */}
            <div className="place-content-center md:hidden px-8 py-12 space-y-8">
              <div className="max-w-[450px]  h-[500px] overflow-hidden">
                <img className="border-4 " src={Image5} alt="enugu-ukwu" />
              </div>

              <div className="max-w-[450px]  h-[500px] overflow-hidden">
                <img className="border-4 " src={Image4} alt="enugu-ukwu" />
              </div>

              <div className="max-w-[450px]  h-[500px] overflow-hidden">
                <img className="border-4 " src={Image1} alt="enugu-ukwu" />
              </div>
            </div>

            {/* {text2 delve deeper} */}
            <ul className="mx-6 text-[20px] items-center justify-center md:flex md:gap-4 mt-6  ">
              <li className="border-2 mt-4 text-center py-3 px-6  rounded-sm text-white hover:bg-white hover:text-[#1A1C21] ">
                <Link to={"/history"}>Delve deeper</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* {gift shop} */}

        {/* {text mobile view} */}
        <div className="md:mx-28 lg:hidden pt-24 pb-10 mx-4">
          <h1 className="text-[#F88603] text-4xl text-center pb-6 mx-8 font-medium ">
            A gift shop with gifts for everyone
          </h1>
          <p className="">
          Get a physical piece of over 1000 years of history delivered to you anywhere you are in the world.
          </p>
        </div>

        <div className="lg:grid grid-cols-2 gap-8 items-center justify-center lg:py-48 mx-4 lg:mx-28 py-8">
          {/* {image gride for giftshop} */}
          <div className=" grid grid-cols-2 space-x-4 items-center justify-center ">
            <div className="space-y-4 ">
              <motion.div
                initial={{ opacity: 0, y: "30%" }}
                //animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "50%" }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={transition1}
                className="shadow-lg max-w-[280px] pb-4 bg-[#FFFAF5]"
              >
                <img
                  className="border-4 flex justify-center items-center p-1 h-[250px] w-full border-white"
                  src={Image2}
                  alt="enugu-ukwu"
                />
                <div className="mx-2 pt-3 pb-1">
                  <p className="text-[10px] text-[#818285]">N5000</p>
                  <h2 className="font-medium text-[15px]">Basket</h2>
                </div>
                <ul className="bg-[#2A564C] rounded-sm items-center justify-center text-center mx-2">
                  <li className="text-white py-4  text-[12px]">
                    <Link to={"/giftshop"}>Add to cart</Link>
                  </li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: "30%" }}
                //animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "50%" }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={transition1}
                className="shadow-lg max-w-[280px] bg-[#FFFAF5] pb-4"
              >
                <img
                  className="border-4 flex justify-center items-center  h-[250px] w-full p-1 border-white"
                  src={Whitecap}
                  alt="enugu-ukwu"
                />
                <div className="mx-2 pt-3 pb-1">
                  <p className="text-[10px] text-[#818285]">N3000</p>
                  <h2 className="font-medium text-[15px]">White face cap</h2>
                </div>
                <ul className="bg-[#2A564C] rounded-md items-center justify-center text-center mx-2">
                  <li className="text-white py-4  text-[12px]">
                    <Link to={"/giftshop"}>Add to cart</Link>
                  </li>
                </ul>
              </motion.div>
            </div>

            <div className="space-y-4">
              <motion.div 
                initial={{ opacity: 0, y: '50%' }}
                //animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '50%' }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={transition1}
              className="shadow-lg max-w-[280px] bg-[#FFFAF5] pb-4">
                <img
                  className="border-4 flex justify-center items-center  h-[250px] w-full p-1 border-white"
                  src={Image7}
                  alt="enugu-ukwu"
                />
                <div className="mx-2 pt-3 pb-1">
                  <p className="text-[10px] text-[#818285]">N2000</p>
                  <h2 className="font-medium text-[15px]">Hand made bags</h2>
                </div>
                <ul className="bg-[#2A564C] rounded-md items-center justify-center text-center mx-2">
                  <li className="text-white py-4  text-[12px]">
                    <Link to={"/giftshop"}>Add to cart</Link>
                  </li>
                </ul>
              </motion.div>

              <motion.div 
                initial={{ opacity: 0, y: '50%' }}
                //animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '50%' }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={transition1}
              className="shadow-lg max-w-[280px] bg-[#FFFAF5] pb-4">
                <img
                  className="border-4 flex justify-center items-center p-1 h-[250px] w-full border-white"
                  src={Okwaoji}
                  alt="enugu-ukwu"
                />
                <div className="mx-2 pt-3 pb-1">
                  <p className="text-[10px] text-[#818285]">N10,000</p>
                  <h2 className="font-medium text-[15px]">Okwa Oji</h2>
                </div>
                <ul className="bg-[#2A564C] rounded-md items-center justify-center text-center mx-2">
                  <li className="text-white py-4 text-[12px]">
                    <Link to={"/giftshop"}>Add to cart</Link>
                  </li>
                </ul>
              </motion.div>

            </div>
          </div>

          {/* { text desktop view} */}
          <div className="hidden lg:block text-right max-w-lg">
            <h1 className="text-[#F88603] text-[48px] tracking-widest font-medium leading-[60px] ">
              A gift shop <br /> with gifts for <br /> everyone
            </h1>
            <p className="text-[17px] ml-10">
            Get a physical piece of over 1000 years of history delivered to you anywhere you are in the world.
            </p>
            <ul className="items-end justify-end md:flex md:gap-4 mt-6  ">
              <li className="border border-[#2A564C] mt-4 text-center py-3 px-6  rounded-sm  hover:bg-[#2A564C] hover:text-white ">
                <Link to={"/giftshop"}>View more gifts</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* {mobile botton for giftshop} */}
        <ul className="lg:hidden items-center justify-center pb-20 md:mx-60 mx-4  ">
          <li className="border-2 border-[#2A564C] mt-4 text-center py-3 px-6 text-[18px]  rounded-sm  hover:bg-[#2A564C] hover:text-white ">
            <Link to="/giftshop">View more gifts</Link>
          </li>
        </ul>

        {/* {call footer} */}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
